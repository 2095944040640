import React, { Component } from "react";
import { Card, CardHeader, CardBody } from 'reactstrap';
import Icon from "react-icons-kit";
import { closeRound } from "react-icons-kit/ionicons/";

import "./SubscribeModal.scss";
export default class SubscribeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, visible, toggle, className } = this.props;
    if (visible) return (
        <div className={`miyosmart-subscribe-container ${className}`}>
        <Card>
            <CardHeader className="miyosmart-subscribe-header">
              <Icon icon={closeRound} onClick={() => toggle(false)} size={23} />
            </CardHeader>
            <CardBody>{children}</CardBody>
        </Card>
        </div>
    ) 
    else return null;
  }
}
