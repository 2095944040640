import React from 'react';
import fbIcon from 'assets/images/footer-facebook.svg';
import igIcon from 'assets/images/footer-instagram.svg';

const Bottom = () => {
    return ( 
        <div className="bottom">
            <div className="bottom-container">
                <div className="bottom-container-social">
                    <div className="bottom-container-social__wrapper">
                        <div className="bottom-container-social__left">
                        <div className="bottom-container-social__left-title">
                            CONNECT WITH US:
                        </div>
                        <div className="bottom-container-social__left-paragraph">
                            <div className="bottom-container-social__left-body">
                            For support or any questions:
                            </div>
                            <div className="bottom-container-social__left-body">
                            Email us at <a href="mailto: support@hoya.com.my">support@hoya.com.my</a>
                            </div>
                            <div className="bottom-container-social__left-body">
                            Call us at 03-6256 0081
                            </div>
                        </div>
                        <div className="bottom-container-social__left-paragraph">
                            <div className="bottom-container-social__left-body">
                            Disclaimer:
                            </div>
                            <div className="bottom-container-social__left-body">
                            The product information on this page has been verified by Medical Device Authority Malaysia (MDA)
                            </div>
                            <div className="bottom-container-social__left-body">
                            with the Registration No: GA6741719-35426
                            </div>
                        </div>
                        </div>
                        <div className="bottom-container-social__right">
                        <div className="bottom-container-social__right-title">
                            SOCIAL MEDIA
                        </div>
                        <div className="bottom-container-social__right-icon-group">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/hoyamalaysia/"
                            >
                                <img className="bottom-container-social__right-icon" src={fbIcon} alt=""/>
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/hoyamalaysia/"
                            >
                                <img className="bottom-container-social__right-icon" src={igIcon} alt=""/>
                            </a>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-container-copyright">
                    <div className="bottom-container-copyright__wrapper">
                        © {new Date().getFullYear()} HOYA. All Rights Reserved.
                        <div className="bottom-container-copyright__right">
                            <div className="bottom-container-copyright__right-privacy">
                                Privacy
                            </div>
                            <a className="bottom-container-copyright__right-term"
                                href="https://miyosmart.hoya.com.my/terms_of_use.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div>Terms of use</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bottom;