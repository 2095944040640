import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";
import { sanitizeError } from "../../_helpers/common";

import NavBar from "components/nav-bar";
import Button from "components/button";
import Bottom from "components/bottom";
import SubscribeModal from "components/SubscribeModal/SubscribeModal";

import landingBg1 from "assets/images/landingBg1.jpg";
import landingBg2 from "assets/images/landingBg2.png";
import landingBg3 from "assets/images/landingBg3.png";
import landingBg4 from "assets/images/landingBg4.png";
import landingBee1 from "assets/images/landing-bee1.png";
import landingBee2 from "assets/images/landing-bee2.png";
import landingBee3 from "assets/images/landing-bee3.png";
import landingBee4 from "assets/images/landing-bee4-&-tech-bee-2.png";
import landingIcon11 from "assets/images/landingIcon11.png";
import landingIcon12 from "assets/images/landingIcon12.png";
import eyeDrops from "assets/images/landing-eye-drops.png";
import contactLens from "assets/images/landing-contact-lens.png";
import spectacleLenses from "assets/images/landing-spectacle-lenses.png";
import subscribedChecked from "assets/images/subscribedChecked.png";

class Landing extends Component {
    constructor(props) {
        super(props);

        this.onScroll = this.onScroll.bind(this);
        this.subscribeContainer = createRef();
        this.subscribeFormik1 = createRef();
        this.subscribeFormik2 = createRef();

        this.state = {
            subscribeContainerY: "",
            subscribeContainer: {
                subscribeStatus: false,
                subscribeMessage: "",
            },
            subscribeModal: {
                visible: true,
                subscribeStatus: false,
                subscribeMessage: "",
            },
        };
    }

    subscribeEmail = async (payload) => {
        const response = await ApiService.post(ServiceApiUrl.EMAILSUBSCRIBE, payload);
        return response;
    };

    handleSubmit = (values, type) => {
        const { email } = values;
        const payload = {
            email,
        };
        this.subscribeEmail(payload)
            .then((response) => {
                this.setState({
                    ...this.state,
                    [type]: {
                        ...this.state[type],
                        subscribeStatus: true,
                        subscribeMessage: "Thank You. You will receive your voucher in your email shortly.",
                    },
                });
                if (type === "subscribeContainer") this.subscribeFormik1.current.setSubmitting(false);
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    [type]: {
                        ...this.state[type],
                        subscribeStatus: false,
                        subscribeMessage: sanitizeError(error),
                    },
                });
                if (type === "subscribeContainer") this.subscribeFormik1.current.setSubmitting(false);
                if (type === "subscribeModal") this.subscribeFormik2.current.setSubmitting(false);
            });
    };

    handleInputClick = (type) => {
        if (this.state[type].subscribeStatus) {
            this.setState({
                ...this.state,
                [type]: {
                    ...this.state[type],
                    subscribeStatus: false,
                },
            });
        }

        if (this.state[type].subscribeMessage) {
            this.setState({
                ...this.state,
                [type]: {
                    ...this.state[type],
                    subscribeMessage: "",
                },
            });
        }
    };

    toggle = (visible) => {
        this.setState({ ...this.state, subscribeModal: { visible: visible } });
    };

    onScroll = () => {
        if (window.pageYOffset > this.subscribeContainer.current.offsetTop - this.subscribeContainer.current.clientHeight) {
            this.setState({ ...this.state, subscribeModal: { visible: false } });
            window.removeEventListener("scroll", this.onScroll, false);
        }
    };

    componentDidMount = () => {
        window.addEventListener("scroll", this.onScroll, false);
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    render() {
        const { subscribeContainer, subscribeModal } = this.state;

        return (
            <div className="landing-page">
                <div className="landing">
                    <NavBar />
                    <div className="landing-1">
                        <div className="landing-1__main">
                            <div className="landing-1__center-circle">
                                <div className="landing-1__center-circle-container">
                                    <div className="landing-1__center-circle-text">
                                        <div className="landing-1__center-circle-text--title bold">60%</div>
                                        <div className="landing-1__center-circle-text--subtitle">slow down</div>
                                        <div className="landing-1__center-circle-text--subtitle">of myopia</div>
                                        <div className="landing-1__center-circle-text--subtitle">progression</div>
                                    </div>
                                </div>
                            </div>
                            <div className="landing-1__left">
                                <div className="landing-1__left-content">
                                    <div className="landing-1__left-content-container">
                                        <div className="bold">
                                            <div className="bold">MiYOSMART: </div>
                                            <div className="bold">the smart way </div>
                                            <div className="bold">to treat myopia </div>
                                            <div className="bold">in children. </div>
                                        </div>
                                        <div className="landing-1__left-content-bottom">
                                            <div>
                                                <Button title="LEARN MORE" customClass="landing-1__left-content-bottom-button" link={"https://www.youtube.com/watch?v=HDt8lNIp0eQ"} />
                                            </div>
                                            <img className="landing-1__landing-bee" src={landingBee1} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img className="landing-1__right" src={landingBg1} alt="" />
                        </div>
                    </div>

                    <div className="landing-2">
                        <div className="landing-2__main">
                            <div className="landing-2__left">
                                <img src={landingBg2} className="landing-2__left-image" alt="" />
                            </div>
                            <div className="landing-2__right">
                                <div className="landing-2__right-wrap">
                                    <div className="landing-2__right-content">
                                        <div className="landing-2__right-text landing-2__right-text--title">What is myopia?</div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>You may have noticed your child</div>
                                            <div>struggling to see clearly at a distance.</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>Do they have a hard time</div>
                                            <div>concentrating at school and at play?</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body">Your child may have myopia, or short-sightedness.</div>
                                    </div>
                                    <div className="landing-2__right-content">
                                        <div className="landing-2__right-text landing-2__right-text--title">How does it happen?</div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>This happens when the eyeball grows fast and becomes</div>
                                            <div>too long. Or when the cornea is too curved and thick.</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body">
                                            <div>Instead of focusing on the retina,</div>
                                            <div>images focus in front of the retina instead.</div>
                                        </div>
                                        <div className="landing-2__right-text landing-2__right-text--body landing-2__right-text--last">
                                            <div>
                                                <div>If left untreated, myopia can</div>
                                                <div>lead to lasting vision problems.</div>
                                            </div>
                                            <img className="landing-2__landing-bee" src={landingBee2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="landing-3">
                        <div className="landing-3__left" />
                        <img className="landing-3__left-image" src={landingBg3} alt="" />
                        <div className="landing-3__right">
                            <div className="landing-3__right-container">
                                <div className="landing-3__right-text landing-3__right-text--title">Myopia is a growing global concern.</div>
                                <div className="landing-3__right-body">
                                    <img className="landing-3__right-body-image" src={landingIcon11} alt="" />
                                    <div className="landing-3__right-body-text">
                                        An estimated 5 billion people, or half of the global population, could be affected by short-sightedness by 2050. <sup>(1)</sup>
                                    </div>
                                </div>
                                <div className="landing-3__right-body">
                                    <img className="landing-3__right-body-image" src={landingIcon12} alt="" />
                                    <div className="landing-3__right-body-text">
                                        Research shows that more time spent on near-work activities is associated with a greater likelihood of myopia. <sup>(2)</sup>
                                    </div>
                                </div>
                                <div className="landing-3__right-text landing-3__right-text--citation">
                                    (1) Holden B.A., Fricke T.R., Wilson D.A., Jong M., Naidoo K.S., Sankaridurg P., Wong T.Y., Naduvilath T.J., Resniko_S. Global Prevalence of Myopia and High Myopia and Temporal Trends from 2000 through 2050.
                                    American Academy of Ophthalmology. 05/2016, vol.123, no. 5, p.1036-1042. https://dol.org/10.1016/j.ophtha.2016.01.006
                                </div>
                                <div className="landing-3__right-text landing-3__right-text--citation">
                                    (2) Huang H-M, Chang DS-T, Wu P-C. The Association between Near Work Activities and Myopia in Children —A Systematic Review and Meta-Analysis. 2015. PLoS ONE 10(10): e0140419.
                                    https://doi.org/10.1371/journal.pone.0140419
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="landing-4">
                        <div className="landing-4__main">
                            <div className="landing-4__text-title">What can you do?</div>
                            <div className="landing-4__text-body">While there is no cure, a variety of treatments have shown to be effective</div>
                            <div className="landing-4__text-body">in slowing or halting myopia progression.</div>
                            <div className="landing-4__image-list">
                                <div className="landing-4__image">
                                    <img src={eyeDrops} className="landing-4__image-image" alt="" />
                                    <div className="landing-4__image-text">Eye Drops</div>
                                </div>
                                <div className="landing-4__image">
                                    <img src={spectacleLenses} className="landing-4__image-image" alt="" />
                                    <div className="landing-4__image-text">Spectacle Lenses</div>
                                </div>
                                <div className="landing-4__image">
                                    <img src={contactLens} className="landing-4__image-image" alt="" />
                                    <div className="landing-4__image-text">Contact Lenses</div>
                                </div>
                            </div>
                            <div className="landing-4__text-body">Spectacle lenses are widely recommended as they are safe and easy for your child to wear.</div>
                        </div>
                    </div>

                    <div className="landing-5">
                        <div className="landing-5__left">
                            <div className="landing-5__left-content">
                                <div className="landing-5__left-content-title">It all starts with an eye exam.</div>
                                <div className="landing-5__left-content-body">Regular eye exams help to detect early signs of visual problems.</div>
                                <div className="landing-5__left-content-body">If either one or both parents are short-sighted, eye exams are even more important.</div>
                                <div className="landing-5__left-content-body landing-5__left-content-body--last">When you identify myopia early, you can slow it down with early, personalised action plans.</div>
                                <div className="landing-5__left-content-title">Is it possible to slow down myopia progression in your child?</div>
                                <div className="landing-5__left-content-answer">Yes, it is possible.</div>
                                <div className="landing-5__left-content-bottom">
                                    <div>
                                        <Button
                                            title="FIND OUT MORE"
                                            type="submit"
                                            customClass="landing-5__left-content-bottom-button"
                                            onClick={() => {
                                                this.props.history.push("/about");
                                            }}
                                        />
                                    </div>
                                    <img className="landing-5__landing-bee" src={landingBee3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="landing-5__right" />
                        <img className="landing-5__right-image" src={landingBg4} alt="" />
                    </div>

                    <div className="landing-6" ref={this.subscribeContainer}>
                        <div className="landing-6__main">
                            <div className="landing-6__title">REDEEM FREE VOUCHER</div>
                            <div className="landing-6__body">
                                <div>Sign up to enjoy 20% off your first pair of</div>
                                <div>MiYOSMART lenses!</div>
                            </div>
                            <Formik
                                ref={this.subscribeFormik1}
                                initialValues={{
                                    email: "",
                                }}
                                onSubmit={(values) => {
                                    this.handleSubmit(values, "subscribeContainer");
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email().required("Required"),
                                })}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="landing-6__subscribe-form" autoComplete="off">
                                            <input
                                                id="email"
                                                type="text"
                                                placeholder="Enter email address"
                                                value={values.email}
                                                onChange={handleChange}
                                                onClick={() => this.handleInputClick("subscribeContainer")}
                                                onBlur={handleBlur}
                                                className="landing-6__text-input"
                                            />
                                            <div className={["landing-6__text-feedback", subscribeContainer.subscribeStatus ? "landing-6__text-feedback--success" : "landing-6__text-feedback--error"].join(" ")}>
                                                {errors.email && touched.email ? errors.email : subscribeContainer.subscribeMessage ? subscribeContainer.subscribeMessage : null}
                                            </div>
                                            <Button title="CLAIM VOUCHER" type="submit" customClass="landing-6__button" disabled={isSubmitting} />
                                        </form>
                                    );
                                }}
                            </Formik>
                            <img className="landing-6__landing-bee" src={landingBee4} alt="" />
                        </div>
                    </div>
                    <Bottom />
                </div>

                <SubscribeModal visible={subscribeModal.visible} toggle={this.toggle} className="subscribe-modal">
                    <div className="subscribe-modal-container">
                        <div className="subscribe-modal-container__wrapper">
                            {!subscribeModal.subscribeStatus ? (
                                <div>
                                    <div className="subscribe-modal-container__title">REDEEM FREE VOUCHER</div>
                                    <div className="subscribe-modal-container__content">
                                        <div>Sign up to enjoy 20% off your first pair of</div>
                                        <div>MiYOSMART lenses!</div>

                                        <Formik
                                            ref={this.subscribeFormik2}
                                            initialValues={{
                                                email: "",
                                            }}
                                            onSubmit={(values) => {
                                                this.handleSubmit(values, "subscribeModal");
                                            }}
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string().email().required("Required"),
                                            })}>
                                            {(props) => {
                                                const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                                return (
                                                    <form onSubmit={handleSubmit} className="subscribe-modal-container__subscribe-form" autoComplete="off">
                                                        <input
                                                            id="email"
                                                            type="text"
                                                            placeholder="Enter email address"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onClick={() => this.handleInputClick("subscribeModal")}
                                                            onBlur={handleBlur}
                                                            className="subscribe-modal-container__text-input"
                                                        />
                                                        <div
                                                            className="subscribe-modal-container__text-feedback 
                            subscribe-modal-container__text-feedback--error">
                                                            {errors.email && touched.email ? errors.email : subscribeModal.subscribeMessage ? subscribeModal.subscribeMessage : null}
                                                        </div>
                                                        <Button title="CLAIM VOUCHER" type="submit" customClass="subscribe-modal-container__button" disabled={isSubmitting} />
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <img className="subscribe-modal-container__icon-checked" src={subscribedChecked} alt="" />
                                    <div className="subscribe-modal-container__title">THANK YOU</div>
                                    <div className="subscribe-modal-container__content">You will receive your voucher in your email shortly.</div>
                                </div>
                            )}
                        </div>
                    </div>
                </SubscribeModal>
            </div>
        );
    }
}

export default withRouter(Landing);
