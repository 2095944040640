export const sanitizeError = (e) => {
  let error = e

  if (typeof e === 'object') {
    if (!!e.response) {
      if (!!e.response.data) {
        error = e.response.data
      } else {
        error = e.response
      }
    } else if (!!e.data) {
      error = e.data
    }

    return error.message
  } else {
    error = e
    return error
  }
}
