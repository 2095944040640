import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Icon from "react-icons-kit";
import { Calendar } from "react-calendar";
import { chevronDown, chevronLeft } from "react-icons-kit/ionicons";
import { ic_chevron_left, ic_chevron_right } from "react-icons-kit/md/";
import { connect } from "react-redux";
import * as moment from "moment";
import Layout from "components/layout";
import Button from "components/button";
import { history } from "../../_helpers/history";
import { alertActions } from "../../_actions";
import { ApiService, ServiceApiUrl } from "../../_helpers/index";

class AddNewOrderNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false,
            existingOrNewSteps: 1,
            respNameListData: [],
            dob: "",
            orderNumber: "",
            name: "",
            dropDownActiveName: "No Data",
            dropDownActiveProfileid: 0,
            hide: false,
            calendarVisible: false,
        };
    }

    componentDidMount() {
        this.getNameList();
    }

    backToProfile = () => {
        history.push("/profile");
    };

    togglePatientNameDropdown = (close) => {
        close === "close"
            ? this.setState({
                  dropdown: false,
              })
            : this.setState({
                  dropdown: !this.state.dropdown,
              });
    };

    dropDownSelected = (profileId, profileName, dob) => {
        this.setState({
            dropDownActiveProfileid: profileId,
            activeDob: dob,

            dropDownActiveName: profileName,
            currentIndex: 1,
        });
    };
    resetActiveDob = () => {
        this.setState((prevState) => ({
            activeDob: "",
        }));
    };

    postLinkOrderNumberWithPayloadForExisting = () => {
        const payload = {
            profileId: this.state.dropDownActiveProfileid,
            profileName: "new=profile",
            DOB: this.state.activeDob,
            OrderNumber: this.state.activeOrderNumber,
        };

        this.postLinkOrderNumber(payload)
            .then((resp) => {
                this.backToProfile();
            })
            .catch((err) => {
                const errMsg = err.response.data ? err.response.data.message : err.message;
                this.props.alertError(errMsg);
            });
    };

    postLinkOrderNumberWithPayloadForNew = () => {
        const payload = {
            profileId: 0,
            profileName: this.state.dropDownActiveName,
            DOB: moment(this.state.activeDob).format("DD/MM/YYYY"),
            OrderNumber: this.state.activeOrderNumber,
        };

        this.postLinkOrderNumber(payload)
            .then((resp) => {
                this.backToProfile();
            })
            .catch((err) => {
                const errMsg = err.response.data ? err.response.data.message : err.message;
                this.props.alertError(errMsg);
            });
    };

    postLinkOrderNumber = async (payload) => {
        const response = await ApiService.post(ServiceApiUrl.CREATELINKPRODUCT, payload);
        return response;
    };

    getNameList = async (payload) => {
        const response = await ApiService.get(ServiceApiUrl.SHOWLINKPROFILE, payload);

        this.setState({
            respNameListData: response.data,
            dropDownActiveName: response.data[0].profileName,
            activeDob: moment(response.data[0].dob).format("DD/MM/YYYY"),
            dropDownActiveProfileid: response.data[0].profileId,
        });
    };
    setExistingOrNewSteps = (step) => {
        this.setState({ existingOrNewSteps: step }, () => {
            this.getNameList();
        });
    };

    pickingNeworExistingApiPost = () => {
        this.state.existingOrNewSteps === 3 ? this.postLinkOrderNumberWithPayloadForNew() : this.postLinkOrderNumberWithPayloadForExisting();
    };

    handleCalendarVisible = () => {
        this.setState((prevState) => ({
            calendarVisible: !prevState.calendarVisible,
        }));
    };

    render() {
        return (
            <Formik
                initialValues={{
                    orderNumber: "",
                    name: "",
                    dob: "",
                    // " "
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.setState(
                        {
                            activeDob: values.dob,
                            dropDownActiveName: values.name,
                            activeOrderNumber: values.orderNumber,
                        },
                        () => {
                            this.pickingNeworExistingApiPost();
                        }
                    );
                    setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    orderNumber: Yup.string().required("Required"),
                    name: this.state.existingOrNewSteps === 3 && Yup.string().required("Required"),
                    dob: this.state.existingOrNewSteps === 3 && Yup.string().required("Required"),
                })}>
                {(props) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
                    return (
                        <Layout>
                            {this.state.calendarVisible ? <div id="overlay" onClick={this.handleCalendarVisible} /> : null}
                            {this.state.calendarVisible ? (
                                <div className="edit-appt-calendar edit-appt-calendar--linked-product">
                                    <div className="calendar-wrapper">
                                        <div className="appt-calendar">
                                            <Calendar
                                                value={values.dob}
                                                onChange={(v) => {
                                                    setFieldValue("dob", v);
                                                    this.handleCalendarVisible();
                                                }}
                                                locale="US"
                                                prevLabel={<Icon icon={ic_chevron_left} size={20} className="calendar_button" />}
                                                nextLabel={<Icon icon={ic_chevron_right} size={20} className="calendar_button" />}
                                                prev2Label={null}
                                                next2Label={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="new-order-number addnewordernumberpage_container">
                                <div className="new-order-number__header">
                                    <p className="title">ADD NEW LINKED PRODUCT</p>
                                    <div className="eye-test-history__back-btn" onClick={() => this.backToProfile()}>
                                        <Icon icon={chevronLeft} size={10} />
                                        <p className="bold">Back</p>
                                    </div>
                                </div>
                                <div className="addnewordernumberpage_flex-v2">
                                    <form onSubmit={handleSubmit} className="addnewordernumberpage_formStyle">
                                        <div className="addnewordernumberpage_cardflexRow2">
                                            <div className="inputContainer">
                                                <label htmlFor="name" className="input-label" style={{ display: "block" }}>
                                                    NAME OF OWNER*
                                                </label>
                                                {this.state.existingOrNewSteps === 1 && (
                                                    <div className="btnExistingNewContainer">
                                                        <button onClick={() => this.setExistingOrNewSteps(2)} className="btnExistingNew">
                                                            EXISTING
                                                        </button>

                                                        <button onClick={() => this.setExistingOrNewSteps(3)} className="btnExistingNew">
                                                            NEW ENTRY
                                                        </button>
                                                    </div>
                                                )}
                                                {this.state.existingOrNewSteps === 2 && (
                                                    <div className="addnewordernumberpage_dropDownContainer">
                                                        <div onClick={() => this.togglePatientNameDropdown()} className="bold addnewordernumberpage_dropDownActiveName">
                                                            {this.state.dropDownActiveName}
                                                            {this.state.dropDownActiveName && <Icon icon={chevronDown} style={{ marginLeft: "3%" }} size={14} />}

                                                            {this.state.dropdown && (
                                                                <div className="addnewordernumberpage_dropDownBox">
                                                                    {this.state.respNameListData.map((p) => {
                                                                        if (p.profileName) {
                                                                            return (
                                                                                <div
                                                                                    className="addnewordernumberpage_dropDownElements bold"
                                                                                    key={p.profileName}
                                                                                    onClick={() => this.dropDownSelected(p.profileId, p.profileName, moment(p.dob).format("DD/MM/YYYY"))}>
                                                                                    {p.profileName}
                                                                                </div>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.existingOrNewSteps === 3 && (
                                                    <Fragment>
                                                        <input id="name" type="text" value={values.name} onChange={handleChange} onBlur={handleBlur} className={errors.name && touched.name ? "text-input error" : "text-input"} />
                                                        {errors.name && touched.name && <div className="input-feedback">{errors.name}</div>}
                                                    </Fragment>
                                                )}
                                            </div>
                                            <div className="inputContainer">
                                            <label htmlFor="ordernumber" className="input-label" style={{ display: "block" }}>
                                                ORDER NUMBER*
                                            </label>
                                            <input
                                                disabled={this.state.existingOrNewSteps === 1}
                                                id="orderNumber"
                                                type="text"
                                                value={values.orderNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.orderNumber && touched.orderNumber ? "text-input error" : "text-input"}
                                            />
                                            {errors.orderNumber && touched.orderNumber && <div className="input-feedback">{errors.orderNumber}</div>}
                                        </div>
                                            <div className="inputContainer">
                                                <label htmlFor="dob" className="input-label" style={{ display: "block" }}>
                                                    DATE OF BIRTH*
                                                </label>
                                                <input
                                                    id="dob"
                                                    type="text"
                                                    value={this.state.existingOrNewSteps === 2 ? this.state.activeDob : values.dob ? moment(values.dob).format("DD/MM/YYYY") : ""}
                                                    disabled={this.state.existingOrNewSteps !== 3}
                                                    placeholder="DD/MM/YYYY"
                                                    onFocus={this.handleCalendarVisible}
                                                    onBlur={handleBlur}
                                                    className={errors.dob && touched.dob ? "text-input error" : "text-input"}
                                                />
                                                {errors.dob && touched.dob && !this.state.calendarVisible && <div className="input-feedback">{errors.dob}</div>}
                                            </div>
                                        </div>
                                        {this.state.existingOrNewSteps !== 1 && (
                                            <div className="addnewordernumberpage_buttonContainer">
                                                <Button
                                                    outline
                                                    type="button"
                                                    title="RESET"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        props.resetForm();
                                                        this.setExistingOrNewSteps(1);
                                                        this.resetActiveDob();
                                                    }}
                                                />

                                                <Button type="submit" title="SUBMIT" disabled={isSubmitting} />
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </Layout>
                    );
                }}
            </Formik>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (error) => {
        dispatch(alertActions.error(error));
    },
});

export default connect(null, mapDispatchToProps)(AddNewOrderNumber);
